<template>

  <!-- The MOBILE part -->
  <div
    v-if="mode==='sidebar'"
    class=""
  >

    <div class="flex justify-between">
      <div>
        <NuxtLink
          to="/"
          class="logo-dark hidden"
        >
          <img
            src="@/assets/img/logo-wordmark-dark.svg"
            alt="Logo"
            width="150px"
            height="41px"
          >
        </NuxtLink>
        <NuxtLink
          to="/"
          class="logo-light hidden"
        >
          <img
            src="@/assets/img/logo-wordmark-light.svg"
            alt="Logo"
            width="150px"
            height="41px"
          >
        </NuxtLink>
      </div>
      <img
        src="@/assets/icons/arrow-left.svg"
        class="icon arrow arrow-left"
        width="35"
        @click="closeDrawer"
        alt="previous slide"
      />
    </div>

    <ul
      tabindex="0"
      class="menu p-0 mt-5"
    >
      <li>
        <NuxtLink
          class="navItem"
          to="/hairstyles"
          :class="{ 'active': isActive('/hairstyles') }"
        >
          Hairstyles
        </NuxtLink>
      </li>
      <li v-if="!enterpriseData">
        <NuxtLink
          to="/hair"
          class="navItem"
          :class="{ 'active': isActive('/hair') }"
        >
          Hair Style Changer
        </NuxtLink>
      </li>
      <li v-if="!enterpriseData">
        <NuxtLink
          class="navItem"
          to="/hair-colour-changer"
          :class="{ 'active': isActive('/hair-colour-changer') }"
        >
          Colour Changer
          <ThebetaTag :options="{navmode: true}"/>
        </NuxtLink>
      </li>
      <!-- <li
        v-if="!enterpriseData"
        class="mt-5"
      >
        <NuxtLink
          class="navItem"
          to="/hairstyles"
          :class="{'active': isActive('/hairstyles')}"
        >
          Hairstyles
        </NuxtLink>
      </li> -->

      <hr class="my-3">

      <li class="">
        <p class="muted">
          Business
        </p>
      </li>
      <li v-if="!enterpriseData">
        <NuxtLink
          class="ml-3 navItem"
          to="/affiliate"
          :class="{'active': isActive('/affiliate')}"
        >
          Affiliate
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="ml-3 navItem"
          to="/barbers"
          :class="{'active': isActive('/barbers')}"
        >
          AI for Barbers
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="ml-3 navItem"
          to="/salons"
          :class="{'active': isActive('/salons')}"
        >
          AI for Hair Salons
        </NuxtLink>
      </li>
      <li>
        <NuxtLink
          class="ml-3 navItem"
          to="/hair-clinics"
          :class="{'active': isActive('/hair-clinics')}"
        >
          AI Hair Clinics
        </NuxtLink>
      </li>

      <ClientOnly>

        <div v-if="!!supabaseUser">
          <hr class="my-3">
          <li>
            <NuxtLink
              :to="`/profile/${supabaseUser.id}`"
              class="justify-between"
            >
              Profile
            </NuxtLink>
          </li>

          <li class="mt-5">
            <a @click="signOut">Logout</a>
          </li>

        </div>

        <div v-else>
          <li class="my-3">
            <a
              @click="authModal.show=true"
              class="btn btn-ghost content-center"
            >
              Log In / Sign Up
            </a>
          </li>
        </div>

        <div
          v-if="validatePermission('view', 'admin_area')"
          class="mt-10"
        >
          <li>
            <span class="muted">Admin</span>
          </li>
          <li>
            <NuxtLink
              to="/admin/users"
              :class="{ 'active': isActive('/admin/users') }"
            >
              Users
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/purchases"
              :class="{ 'active': isActive('/admin/purchases') }"
            >
              Purchases
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/jobs"
              :class="{ 'active': isActive('/admin/jobs') }"
            >
              Jobs
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/previews"
              :class="{ 'active': isActive('/admin/previews') }"
            >
              Previews
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/emails"
              :class="{ 'active': isActive('/admin/emails') }"
            >
              Emails
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/styles"
              :class="{ 'active': isActive('/admin/styles') }"
            >
              Styles
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/colours"
              :class="{ 'active': isActive('/admin/colours') }"
            >
              Colours
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/crons"
              :class="{ 'active': isActive('/admin/crons') }"
            >
              Crons
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/admin/affiliates"
              :class="{ 'active': isActive('/admin/affiliates') }"
            >
              Affiliates
            </NuxtLink>
          </li>
          <li>
            <div class="form-control">
              <label class="label cursor-pointer">
                <span class="label-text">Show JSON</span>
                <input
                  type="checkbox"
                  class="toggle ml-5"
                  checked
                  v-model="adminSettings.showJSON"
                />
              </label>
            </div>
          </li>
        </div>
      </ClientOnly>

      <li class="mb-5">
        <div class="flex flex-row justify-between">
          <!-- <label class="label">
            <span class="label-text">Theme</span>
          </label> -->
          <label class="flex cursor-pointer gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
            <input
              type="checkbox"
              true-value="myLight"
              false-value="myDark"
              v-model="colorScheme"
              class="toggle theme-controller"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle
                cx="12"
                cy="12"
                r="5"
              />
              <path
                d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"
              />
            </svg>
          </label>
        </div>
      </li>

    </ul>

  </div>

  <!-- The DESKTOP part -->
  <div
    v-else
    class="justify-end flex-auto font-light hidden md:flex"
  >

    <div class="flex items-center">

      <ul
        class="menu menu-horizontal"
        v-if="!enterpriseData"
      >

        <li>
          <NuxtLink
            class="navItem btn btn-ghost"
            to="/hairstyles"
            :class="{ 'active': isActive('/hairstyles') }"
          >
            Hairstyles
          </NuxtLink>
        </li>

        <li>
          <NuxtLink
            to="/hair"
            class="navItem"
            :class="{ 'active': isActive('/hair') }"
          >
            Style Changer
          </NuxtLink>
        </li>
        <li>
          <NuxtLink
            class="navItem"
            to="/hair-colour-changer"
            :class="{ 'active': isActive('/hair-colour-changer') }"
          >
            Colour Changer
            <ThebetaTag :options="{navmode: true}"/>
          </NuxtLink>
        </li>

        <!-- <li>
          <NuxtLink
            class="navItem"
            to="/hair-colour-changer"
            :class="{ 'active': isActive('/hair-colour-changer') }"
          >
            Colours
          </NuxtLink>
        </li>

        <li>
          <NuxtLink
            v-if="!enterpriseData"
            class="navItem btn btn-ghost"
            to="/hair"
            :class="{'active': isActive('/hair')}"
          >
            Get started
          </NuxtLink>
        </li> -->


      </ul>


      <div
        class="dropdown dropdown-hover mr-2"
        v-if="!enterpriseData"
      >
        <label
          tabindex="0"
          class="btn btn-ghost capitalize navItem"
        >
          Business
          <svg
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content z-50 p-2 shadow bg-base-200 rounded-box w-52"
        >
          <li>
            <NuxtLink
              to="/affiliate"
              :class="{'active gradient-text-1': isActive('/affiliate')}"
            >
              Affiliate Program
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/barbers"
              :class="{'active gradient-text-1': isActive('/barbers')}"
            >
              Barbers
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/salons"
              :class="{'active gradient-text-1': isActive('/salons')}"
            >
              Hair Salons
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              to="/hair-clinics"
              :class="{'active gradient-text-1': isActive('/hair-clinics')}"
            >
              Hair Clinics
            </NuxtLink>
          </li>
        </ul>
      </div>

      <ClientOnly>

        <div
          class="dropdown dropdown-hover"
          v-if="validatePermission('view', 'admin_area')"
        >
          <label
            tabindex="0"
            class="btn btn-ghost capitalize navItem"
          >
            Admin
            <svg
              class="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </label>
          <ul
            tabindex="0"
            class="p-2 dropdown-content z-30 menu bg-base-200 shadow rounded-box w-52"
          >
            <li>
              <NuxtLink
                to="/admin/users"
                :class="{'active': isActive('/admin/users')}"
              >
                Users
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/purchases"
                :class="{'active': isActive('/admin/purchases')}"
              >
                Purchases
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/jobs"
                :class="{ 'active': isActive('/admin/jobs') }"
              >
                Jobs
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/previews"
                :class="{'active': isActive('/admin/previews')}"
              >
                Previews
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/emails"
                :class="{'active': isActive('/admin/emails')}"
              >
                Emails
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/styles"
                :class="{'active': isActive('/admin/styles')}"
              >
                Styles
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/colours"
                :class="{ 'active': isActive('/admin/colours') }"
              >
                Colours
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/crons"
                :class="{'active': isActive('/admin/crons')}"
              >
                Crons
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/affiliates"
                :class="{ 'active': isActive('/admin/affiliates') }"
              >
                Affiliates
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/admin/roles-permissions"
                :class="{ 'active': isActive('/admin/roles-permissions') }"
              >
                Permissions
              </NuxtLink>
            </li>
            <li>
              <div class="form-control">
                <label class="label cursor-pointer">
                  <span class="label-text">Show JSON</span>
                  <input
                    type="checkbox"
                    class="toggle ml-5"
                    checked
                    v-model="adminSettings.showJSON"
                  />
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div
          class="dropdown dropdown-hover dropdown-end"
          v-if="supabaseUserData"
        >

          <label
            tabindex="0"
            class="btn btn-ghost btn-circle"
          >
            <div class="indicator">
              <Transition name="fade">
                <span
                  v-if="notificationBadgeCount"
                  class="indicator-item badge badge-secondary"
                >{{ notificationBadgeCount }}</span>
              </Transition>
              <img
                id="notifications_icon"
                src="@/assets/icons/bell.svg"
                class="icon"
                width=25
                alt="bell icon"
              />
            </div>
          </label>

          <ul
            tabindex="0"
            class="p-2 dropdown-content z-30 menu bg-base-200 shadow rounded-box notifications-list"
          >
            <li
              v-for="notification in supabaseUserData?.notifications"
              @click="notificationClick(notification)"
            >
              <TheNotification :notification="notification" />
            </li>
          </ul>
        </div>

        <div class="dropdown dropdown-hover dropdown-end">
          <label
            tabindex="0"
            class="btn btn-ghost btn-circle"
            id="themeIcon"
          >
            <svg
              class="dark-only"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
            <svg
              class="light-only"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle
                cx="12"
                cy="12"
                r="5"
              />
              <path
                d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"
              />
            </svg>
          </label>

          <ul
            tabindex="0"
            class="p-2 dropdown-content z-30 menu bg-base-200 shadow rounded-box w-auto"
          >

            <li>
              <label class="flex cursor-pointer w-full justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                </svg>
                <input
                  type="checkbox"
                  true-value="myLight"
                  false-value="myDark"
                  v-model="colorScheme"
                  class="toggle theme-controller"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="5"
                  />
                  <path
                    d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"
                  />
                </svg>
              </label>
            </li>
          </ul>
        </div>

        <div
          v-if="!!supabaseUser"
          class="dropdown dropdown-hover dropdown-end"
        >
          <label
            tabindex="0"
            class="btn btn-ghost btn-circle"
          >
            <img
              src="@/assets/icons/profile.svg"
              width="25"
              alt="Acount"
              class="icon"
            >
          </label>
          <ul
            tabindex="0"
            class="p-2 dropdown-content z-30 menu bg-base-200 shadow rounded-box w-52"
          >
            <li>
              <NuxtLink
                :to="`/profile/${supabaseUser.id}`"
                class="justify-between w-full"
              >
                Profile
              </NuxtLink>
            </li>
            <li
              class="muted px-4 py-3"
              v-if="supabaseUserData?.affiliates?.length"
            >
              Affiliate Accounts
            </li>
            <li v-for="affiliate in supabaseUserData?.affiliates">
              <NuxtLink
                :to="`/affiliate/dashboard/${affiliate.id}`"
                class="justify-between w-full"
              >
                {{affiliate.promo_code}}
              </NuxtLink>
            </li>
            <li class="mt-10"><a
                @click="signOut"
                class="w-full"
              >Logout</a></li>
          </ul>
        </div>

        <div v-else>
          <button
            @click="authModal.show=true"
            class="btn btn-ghost"
          >
            Log In / Sign Up
          </button>
        </div>

        <!-- <div class="dropdown dropdown-end" v-if="!!supabaseUser">
              <label tabindex="0" class="btn btn-ghost btn-circle avatar">
                <div class="w-10 rounded-full p-1">
                  <img  
                    src="@/assets/icons/profile.svg" 
                    width="35"
                    alt="Acount" 
                    class="icon"
                  >
                </div>
              </label>
              <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-200 rounded-box w-52">
              </ul>
            </div> -->

        <!-- <li>
              <div class="dropdown dropdown-end">
                <label tabindex="0" class="btn m-1">
                  
                </label>
                <ul tabindex="0" class="dropdown-content menu menu-compact p-2 shadow bg-base-200 rounded-box w-52">
                  <li><span class="muted">Theme</span></li>
                  <li><a>Light</a></li>
                  <li><a>Dark</a></li>
                  <li><a>System</a></li>
                </ul>
              </div>
            </li> -->

      </ClientOnly>

    </div>


  </div>

  <TheAuthModal
    v-if="authModal.show"
    :modalData="authModal"
    @closeModal="(authModal.show=false)"
  />


</template>

<style scoped>

[data-theme='dark'] #themeIcon #sunLogo {
  display: inline;
}

[data-theme='light'] #themeIcon #moonLogo {
  display: inline;
}

.navbar .navItem {
  font-weight:400;
  @apply text-base p-3;
}

.dropdown .btn:hover,
.dropdown .btn:active,
.dropdown .btn:focus {
  /* background: none; */
  --tw-bg-opacity: 0.1;
}

[data-theme='dark'] nav.scrolled {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

nav {
  @apply bg-base-100;
  position: relative;
  top:0;
  z-index: 10;
  transition: box-shadow 0.1s ease-in-out;
}

nav.scrolled {
  @apply shadow-2xl;
}

.nav-inner  {
  margin: auto;
  width: 100%;
  max-width: 1600px;
}

.notifications-list {
  width: 400px;
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

/* Active class */
.active {
  @apply opacity-65;
}
    
</style>

<script setup>

const props = defineProps({
  mode: {
    type: String,
    default: 'horizontal'
  } 
})

const emit = defineEmits(['closeDrawer'])

const config = useRuntimeConfig().public
const adminSettings = useAdminSettings()
const { $supabase } = useNuxtApp()
const colorScheme = useColorScheme()
const supabaseUser = useSupabaseUser()
const supabaseUserData = useSupabaseUserData()
const route = useRoute()
const searchData = ref({
  'text': '',
  'status': 'waiting',
  'otherParams': null
})
const reactiveQuery = computed(() => route.query.query);
const authModal = ref({
  show: false,
  mode: 'default'
})
const showMobMenu = ref(false)
const enterpriseData = useEnterpriseData()
const { $dpr } = useNuxtApp()
const view = ref({
  atTopOfPage: true
})

// define the mode as a prop



onMounted(() => {
  console.log("Topnav mounted")
  // console.log('$dpr:', $dpr);

  if(supabaseUserData.value) {
    colorScheme.value = supabaseUserData.value.personal_settings.theme || 'myDark'
  }

})

watch(supabaseUserData, (newUserData) => {
  console.log("supabaseUserData changed:", newUserData)
  colorScheme.value = newUserData?.personal_settings?.theme || 'myDark'
})

// Lets watch adminsettings and persist the value to session storage
watch(adminSettings, (newAdminSettings) => {
  console.log("adminSettings changed:", newAdminSettings)
  sessionStorage.setItem('adminSettings', JSON.stringify(newAdminSettings))
}, { deep: true })

function notificationClick(notification) {
  console.log("Notification clicked:", notification.data.click_action)

  if(notification.data.click_action.type === 'navigation') {

    let destination

    // if the page is profile page, append the user id to the path
    if(notification.data.click_action.page === '/profile') {
      destination = `${notification.data.click_action.page}/${supabaseUser.value.id}`
    }
    
    // if click_action has a focus_element value, append it to the query
    if(notification.data.click_action.focus_element) {
      destination = `${destination}#${notification.data.click_action.focus_element}`
    }

    console.log("destination:", destination)

    navigateTo(destination)

  }

}

function isActive (name) {
  return route.path === name
}

function saveTheme () {

  if(supabaseUserData.value) {

    supabaseUserData.value.personal_settings.theme = colorScheme.value

    saveUserData()
  }

}

function closeDrawer() {
  console.log("closing drawer")
  emit('closeDrawer')
}

function handleScroll() {
  // when the user scrolls, check the pageYOffset
  if (window.scrollY  > 0) {
    // user is scrolled
    if (view.value.atTopOfPage) view.value.atTopOfPage = false
  } else {
    // user is at top of page
    if (!view.value.atTopOfPage) view.value.atTopOfPage = true
  }
}


// computed value for notificationBadgeCount
const notificationBadgeCount = computed(() => {

  if(!supabaseUserData.value?.notifications) return 0

  // filter out notifications that have been read, where notification.data.read = true
  let unreadCount = supabaseUserData.value?.notifications?.filter((notification) => !notification.data?.read).length || 0

  return unreadCount
})

async function doSearch () {
  console.log("doing search for:", searchData.value.text)

  searchData.value.status = 'loading'

  navigateTo({
    path: '/search',
    query: {
      query: searchData.value.text,
    }
  })
  searchData.value.status = 'waiting'
  
}

const computedThemeColor = computed(() => {
  return colorScheme.value === 'myDark' ? 'myDark' : 'myLight';
});

watch(colorScheme, (newColorScheme) => {
  console.log("colorScheme changed:", newColorScheme)
  if(supabaseUserData.value) {
    supabaseUserData.value.personal_settings.theme = newColorScheme
    saveUserData()
  }

})

useHead({
    htmlAttrs: {
      'data-theme': colorScheme
    },
    meta: [
      {
        name: 'theme-color',
        content: computedThemeColor ,
      }
    ]
  }, {
    mode: 'client'
})

</script>