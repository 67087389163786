<template>

  <footer v-if="subDomain">

    <div class="flex justify-center items-center py-24">
      <NuxtLink
        to="https://aihairstyles.com"
        class="logo-dark hidden text-center"
        target="_blank"
      >
        <span class="text-xs muted">Powered by</span>
        <img
          src="@/assets/img/logo-wordmark-dark.svg"
          alt="Logo"
          width="125"
        >
        <p class="muted text-xs p-3">
          v
          {{ metadata.buildMajor }}.
          {{ metadata.buildMinor }}.
          {{ metadata.buildRevision }}
        </p>
      </NuxtLink>
      <NuxtLink
        to="https://aihairstyles.com"
        class="logo-light hidden text-center"
        target="_blank"
      >
        <span class="text-xs muted">Powered by</span>
        <img
          src="@/assets/img/logo-wordmark-light.svg"
          alt="Logo"
          width="125"
        >
        <p class="muted text-xs p-3">
          v
          {{ metadata.buildMajor }}.
          {{ metadata.buildMinor }}.
          {{ metadata.buildRevision }}
        </p>
      </NuxtLink>
    </div>
  </footer>

  <footer
    v-else
    class="footer p-10 text-base-content"
  >
    <div class="flex flex-col">
      <NuxtLink
        to="/"
        class="logo-dark hidden"
      >
        <img
          src="@/assets/img/logo-wordmark-dark.svg"
          alt="Logo"
          width="150"
          height="41"
        >
      </NuxtLink>
      <NuxtLink
        to="/"
        class="logo-light hidden"
      >
        <img
          src="@/assets/img/logo-wordmark-light.svg"
          alt="Logo"
          width="150"
          height="41"
        >
      </NuxtLink>
      <div>
        <p class="muted text-xs pl-2">
          v
          {{ metadata.buildMajor }}.
          {{ metadata.buildMinor }}.
          {{ metadata.buildRevision }}
        </p>
        <p class="muted text-xs pl-2 mt-2">
          {{ metadata.build }}
        </p>
      </div>
    </div>
    <div class="linkCols">
      <span class="footer-title">Tools</span>
      <!-- <NuxtLink to="/api" class="">
        API
      </NuxtLink>
      <NuxtLink to="/faq" class="">
        FAQ
      </NuxtLink> -->
      <!-- <NuxtLink
        to="/ai-face-shape-analysis-hairstyle-recommendations"
        class=""
      >
        AI Face Shape Analysis
      </NuxtLink> -->
      <NuxtLink
        to="/hair-colour-changer"
        class=""
      >
        AI hair colour changer
      </NuxtLink>
      <NuxtLink
        to="/hair"
        class=""
      >
        AI hair style try on
      </NuxtLink>
    </div>
    <div class="linkCols">
      <span class="footer-title">Business</span>
      <!-- <NuxtLink to="/api" class="">
        API
      </NuxtLink>
      <NuxtLink to="/faq" class="">
        FAQ
      </NuxtLink> -->
      <NuxtLink
        to="/barbers"
        class=""
      >
        AI for barbers
      </NuxtLink>
      <NuxtLink
        to="/salons"
        class=""
      >
        AI for salons
      </NuxtLink>
      <NuxtLink
        to="/hair-clinics"
        class=""
      >
        AI for hair clinics
      </NuxtLink>
    </div>
    <div class="linkCols">
      <span class="footer-title">Company</span>
      <!-- <NuxtLink to="/api" class="">
        API
      </NuxtLink>
      <NuxtLink to="/faq" class="">
        FAQ
      </NuxtLink> -->
      <NuxtLink
        to="/blog"
        class=""
      >
        Blog
      </NuxtLink>
      <NuxtLink
        to="/contact"
        class=""
      >
        Contact
      </NuxtLink>
      <NuxtLink
        to="/request"
        class=""
      >
        Request
      </NuxtLink>
      <!-- <NuxtLink to="/business" class="">
        Business
      </NuxtLink> -->
      <NuxtLink
        to="/affiliate"
        class=""
      >
        Affiliate program
      </NuxtLink>
    </div>
    <div class="linkCols">
      <span class="footer-title">Legal</span>
      <NuxtLink
        to="/terms"
        class=""
      >
        Terms of use
      </NuxtLink>
      <NuxtLink
        to="/privacy-policy"
        class=""
      >
        Privacy policy
      </NuxtLink>
      <NuxtLink
        to="/cookie-policy"
        class=""
      >
        Cookie policy
      </NuxtLink>
    </div>
  </footer>

</template>

<style scoped>

footer {
  max-width: 1600px;
  margin:auto;
}

footer .linkCols {
  gap: 0px;
  width: 100%;
}

footer .linkCols a {
  min-height: 48px;
  min-width: 48px;
  padding-block: 14px;
  width: 100%;
  display: flex;
  align-items: center;
}
    
</style>

<script setup>

import metadata from '../appVersion.json'

const subDomain = useSubdomain()

</script>