<template>
  
  <div class="flex flex-col items-start" :class="{'bg-accent bg-opacity-20': !notification.data?.read}">
    <p class="font-bold">
      {{ notification.data?.title }}
    </p>
    <p>
      {{ notification.data?.content }}
    </p>
    <div class="flex justify-between w-full">
      <TheRelativeDate 
        :dateNumber="new Date(notification.created_at).getTime()" 
        :showTooltip="false"
        class="text-xs muted block"
        />
      <span v-if="!notification.data?.read" @click.stop="markNotificationRead(notification)" class="text-xs muted block">
        Mark as read
      </span>
    </div>
  </div>



</template>

<script setup>

const { $supabase } = useNuxtApp()

defineProps({
  notification: {
    type: Object,
    required: true
  }
});


function markNotificationRead(notification) {
  console.log("Marking notification read:", notification.id)

  // if it's already read, do nothing
  if (notification.data.read) return

  notification.data.read = true

  $supabase
    .from('notifications')
    .update({ data: notification.data })
    .eq('id', notification.id)
    .then(({ data, error }) => {
      if (error) {
        console.log("Error marking notification read:", error)
      } else {
        console.log("Notification marked read:", data)
      }
    })
}


</script>